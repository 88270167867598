import { Buffer } from 'buffer';

export const getBasicAuthHeader = (email: string) => {
  const bearerToken = localStorage.getItem('authToken');
  const basicAuthCredentialsEncoded64 = Buffer.from(`${email}:${bearerToken}`, 'utf-8').toString('base64');

  return {
    Authorization: `Basic ${basicAuthCredentialsEncoded64}`,
  };
};
