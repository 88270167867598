import axios from './axios';
import { getBasicAuthHeader } from '../helpers/requestHelper';
import { config } from '../config/config';

export const salesService = {
  getSales,
  getSaleDetail,
};

async function getSales(email: string) {
  const basicAuthHeader = getBasicAuthHeader(email);

  const res = await axios.get(`${config.backendUrl}/sales?newAdmin=true`, {
    headers: basicAuthHeader,
  });
  return res.data;
}

async function getSaleDetail(email: string, saleId: number) {
  const basicAuthHeader = getBasicAuthHeader(email);

  const res = await axios.get(`${config.backendUrl}/sales/${saleId}`, {
    headers: basicAuthHeader,
  });
  return res.data;
}
