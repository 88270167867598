import { useState } from 'react';
import { useLocation, Location, useParams, Params, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, SxProps } from '@mui/material';
import { NavigationItem, navigationItems } from '../../data/navigation';
import { measures } from '../../data/measures';
import { useAuthContext } from '../../hooks/useAuth';
import { useSalesContext } from '../../hooks/useSales';
import { getViewTitle, ViewTitle } from '../../helpers/viewHelper';
import HeaderToolbarContent from './HeaderToolbarContent';
import Sidebar from './Sidebar';

const toolbarStyles: SxProps = {
  display: 'flex',
  height: measures.headerHeight,
  px: { xs: 2.5 },
  pl: { md: `calc(1rem + ${measures.sidebarWidth}px)` },
};

const Header = () => {
  const { auth } = useAuthContext();
  const { getSales } = useSalesContext();
  const { pathname }: Location = useLocation();
  const navigate = useNavigate();
  const params: Params<string> = useParams();
  const [mobileOpen, setMobileOpen] = useState<boolean>(false);
  const viewTitle: ViewTitle | undefined = getViewTitle(pathname, params);
  const isSidebarView: boolean = !!navigationItems.find(
    (navigationItem: NavigationItem) => pathname === navigationItem.to,
  );

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  const handleRefresh = () => getSales(auth.user?.email!);

  const leftIconHandler = isSidebarView ? handleDrawerToggle : () => navigate(-1);

  return (
    <>
      <AppBar position="fixed" sx={{ boxShadow: 'none' }}>
        <Toolbar sx={toolbarStyles}>
          <HeaderToolbarContent
            isSidebarView={isSidebarView}
            viewTitle={viewTitle!}
            leftIconHandler={leftIconHandler}
            rightIconHandler={handleRefresh}
          />
        </Toolbar>
      </AppBar>
      <Sidebar mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
    </>
  );
};

export default Header;
