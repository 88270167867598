import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Button, Box, Typography, Theme, SxProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Formik, Form, Field } from 'formik';
import ShopflixLogo from '../assets/Logo_Purple_Isotype.svg';
import FormTextField from '../components/common/forms/FormTextField';
import { RoutesEnum } from '../data/navigation';
import schemas from '../data/schemas';
import { LoginRequest } from '../types/auth.types';
import { useAuthContext } from '../hooks/useAuth';
import { getDeviceType } from '../helpers/deviceHelper';

const formStyle = (theme: Theme): SystemStyleObject<Theme> => ({
  bgcolor: 'common.white',
  display: 'flex',
  flexDirection: 'column',
  gap: 2,
  py: 4,
  px: 3,
  borderRadius: 2,
  boxShadow: theme.shadows[1],
  width: 'calc(100vw - 2rem)',
  [theme.breakpoints.up('md')]: { maxWidth: '50%' },
});

const logoStyle: SxProps = { height: '48px', width: '88.5px', mx: 'auto' };

interface LoginValues {
  email: string;
  password: string;
}

const Login = () => {
  const navigate = useNavigate();
  const { auth, login } = useAuthContext();

  const submitHandler = (values: LoginValues) => {
    const loginValues: LoginRequest = {
      ...values,
      device: getDeviceType(),
      // Leave below values hardcoded as they are: both are required but unused
      version: '0.1',
      // playerId value is for mobile app devices to send push notifications, unused for web app version
      onesignalPlayerId: ' ',
    };
    login(loginValues);
  };

  useEffect(() => {
    if (auth.isLoggedIn) {
      navigate(RoutesEnum.ACTIVITY);
    }
  }, [navigate, auth.isLoggedIn]);

  return (
    <div className="main auth-background">
      <Formik
        initialValues={{ email: '', password: '' }}
        onSubmit={submitHandler}
        validationSchema={schemas.LoginSchema}
      >
        <Container component={Form} sx={formStyle}>
          <Box component="img" src={ShopflixLogo} alt="Shopflix" sx={logoStyle} />
          <Typography variant="h2" color="secondary.dark">
            Iniciar sesión en su cuenta
          </Typography>
          <Field
            label="E-mail"
            name="email"
            component={FormTextField}
            placeholder="you@example.com"
            startAdornment="email"
          />
          <Field label="Contraseña" name="password" type="password" component={FormTextField} />
          <Button variant="contained" color="primary" type="submit" disableFocusRipple>
            Ingresar
          </Button>
          {auth?.errorMessage !== null && (
            <Typography variant="h2" color="error.main">
              No se pudo iniciar sesión: {auth.errorMessage}
            </Typography>
          )}
        </Container>
      </Formik>
    </div>
  );
};

export default Login;
