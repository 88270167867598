export const getAvatarInitials = (avatarName: string): string => {
  const avatarInitials = avatarName
    .split(' ')
    .map((fullNameChunk: string) => fullNameChunk[0])
    .join('');

  if (avatarInitials.length > 2) {
    return avatarInitials.substring(0, 2);
  }
  return avatarInitials;
};

export const getHelloName = (avatarName: string) => {
  const firstName = avatarName.split(' ')[0];

  if (firstName.length > 12) {
    const trailingName = firstName.slice(0, 12).concat('...');
    return `Hola ${trailingName}`;
  }
  return `Hola ${firstName}`;
};
