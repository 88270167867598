import { Box, Typography, Chip, SxProps } from '@mui/material';
import { colors } from '../../data/colors';

const sectionHeaderStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  bgcolor: colors.gray[100],
  px: 2,
  py: 1.25,
};

const chipStyle: SxProps = {
  bgcolor: colors.indigo[200],
  '&>span': { color: colors.indigo[800] },
};

interface ActivityHeaderProps {
  leftContent: string;
  chipContent?: string | number;
  rightContent: string;
}

const ActivityHeader = ({ leftContent, chipContent, rightContent }: ActivityHeaderProps) => {
  return (
    <Box sx={sectionHeaderStyle}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography variant="subtitle1" sx={{ mr: 1 }}>
          {leftContent}
        </Typography>
        {(chipContent || chipContent === 0) && <Chip label={chipContent} sx={chipStyle} />}
      </Box>
      <Typography variant="subtitle1">{rightContent}</Typography>
    </Box>
  );
};

export default ActivityHeader;
