import { Card, Box, SxProps, Typography } from '@mui/material';
import { colors } from '../../data/colors';
import CustomSvgIcon from '../common/icons/CustomSvgIcon';

const emptyCardStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  p: 2,
  gap: '1rem',
  borderRadius: 'none',
  boxShadow: 'none',
};

const iconWrapper: SxProps = {
  width: 'fit-content',
  px: 2.5,
  pt: 2,
  pb: 1.5,
  borderRadius: '999px',
  bgcolor: colors.indigo[100],
};

interface EmptyActivityCardProps {
  message: string;
}

const EmptyActivityCard = ({ message }: EmptyActivityCardProps) => {
  return (
    <Card sx={emptyCardStyles}>
      <Box sx={iconWrapper}>
        <CustomSvgIcon icon="trendingUp" sx={{ stroke: colors.indigo[600] }} />
      </Box>
      <Typography variant="h3" color={colors.gray[900]}>
        {message}
      </Typography>
    </Card>
  );
};

export default EmptyActivityCard;
