import { FormControl, InputLabel, TextField, InputAdornment, Tooltip } from '@mui/material';
import { Error as ErrorIcon, Email as EmailIcon } from '@mui/icons-material';
import { FieldInputProps, FormikValues } from 'formik';

interface FormTextFieldProps {
  field?: FieldInputProps<FormikValues>;
  form?: any;
  classes: any;
  label: string;
  noFullWidth?: boolean;
  type?: string;
  value?: string | number;
  onChange?: (e: any) => void;
  disabled?: boolean;
  placeholder?: string;
  startAdornment?: 'email';
}

function FormTextField({
  field,
  form,
  label,
  noFullWidth,
  type = 'text',
  value,
  onChange,
  disabled,
  placeholder,
  startAdornment,
}: FormTextFieldProps) {
  const errorMessage: string | undefined = form?.touched?.[field?.name!] && form?.errors?.[field?.name!];

  return (
    <FormControl variant="outlined" sx={{ my: 0.5 }} fullWidth={!noFullWidth}>
      <InputLabel className="input-label" shrink={true}>
        {label}
      </InputLabel>
      <TextField
        margin="none"
        variant="outlined"
        size="small"
        error={!!errorMessage}
        InputProps={{
          sx: {
            input: 'input-element',
            disabled: 'input-disabled',
          },
          startAdornment: startAdornment && (
            <InputAdornment position="start">
              {startAdornment === 'email' && <EmailIcon color="info" sx={{ width: '16px' }} />}
            </InputAdornment>
          ),
          endAdornment: errorMessage && (
            <Tooltip title={form.errors[field?.name!].toString()} enterTouchDelay={300}>
              <InputAdornment position="end">
                <ErrorIcon color="error" sx={{ width: '20px' }} />
              </InputAdornment>
            </Tooltip>
          ),
        }}
        {...field}
        onChange={onChange || field?.onChange}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        value={value || field?.value}
      />
    </FormControl>
  );
}

export default FormTextField;
