import { Chip, SxProps } from '@mui/material';
import { SalesStatusPillVariant } from '../../types/sale.types';
import { colors } from '../../data/colors';

export interface SalesStatusPillProps {
  label: string;
  variant: SalesStatusPillVariant;
}

const SalesStatusPill = ({ label, variant = 'green' }: SalesStatusPillProps) => {
  const pillColors: SxProps = {
    bgcolor: colors[variant][100],
    '&>span': { color: colors[variant][800] },
  };

  return <Chip label={label} sx={pillColors} />;
};

export default SalesStatusPill;
