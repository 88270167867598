import { useEffect, useMemo } from 'react';
import { Paper, SxProps } from '@mui/material';
import ActivityCard from '../components/activity/ActivityCard';
import ActivityHeader from '../components/activity/ActivityHeader';
import EmptyActivityCard from '../components/activity/EmptyActivityCard';
import FixedError from '../components/common/FixedError';
import Loading from '../components/common/animations/Loading';
import { useAuthContext } from '../hooks/useAuth';
import { useSalesContext } from '../hooks/useSales';
import { SplitSales, getSalesSplitByToday, getTotalAmount } from '../helpers/salesHelper';
import { Sale } from '../types/sale.types';
import { texts } from '../data/texts';

const paperStyle: SxProps = {
  width: { xs: '100vw', md: '60%' },
  height: 'fit-content',
  borderRadius: { xs: 'none', md: 2 },
  mt: { md: 3 },
};

const Activity = () => {
  const { auth } = useAuthContext();
  const { sales, getSales } = useSalesContext();

  const splitSales: SplitSales | null = useMemo(
    () => sales.sales && getSalesSplitByToday(sales.sales),
    [sales.sales],
  );

  useEffect(() => {
    if (!sales.loading && !sales.sales && !sales.salesErrorMessage) {
      getSales(auth.user?.email!);
    }
  }, [auth.user?.email, getSales, sales.salesErrorMessage, sales.loading, sales.sales]);

  return !sales.loading && (sales.salesErrorMessage || !sales?.sales) ? (
    <FixedError />
  ) : (
    <Paper sx={paperStyle}>
      {sales.loading ? (
        <Loading />
      ) : !sales?.sales?.length ? (
        <EmptyActivityCard message={texts.missingInitialOperation} />
      ) : (
        <>
          <ActivityHeader
            leftContent="HOY"
            chipContent={splitSales?.todaySales?.length}
            rightContent={
              splitSales?.todaySales?.length ? `USD ${getTotalAmount(splitSales?.todaySales)}` : ''
            }
          />
          {!!splitSales?.todaySales.length ? (
            splitSales?.todaySales
              ?.sort((saleA: Sale, saleB: Sale) => saleB.createdAt - saleA.createdAt)
              .map((sale: Sale, index: number) => (
                <ActivityCard key={`today-${sale.id}-${index}`} {...sale} clickToDetails />
              ))
          ) : (
            <EmptyActivityCard message={texts.emptyOperation} />
          )}
          <ActivityHeader
            leftContent="ANTERIORES"
            chipContent={splitSales?.beforeTodaySales?.length}
            rightContent={
              splitSales?.beforeTodaySales[0]?.amountUSD
                ? `USD ${getTotalAmount(splitSales?.beforeTodaySales)}`
                : ''
            }
          />
          {!!splitSales?.beforeTodaySales.length &&
            splitSales?.beforeTodaySales
              ?.sort((saleA: Sale, saleB: Sale) => saleB.createdAt - saleA.createdAt)
              .map((sale: Sale, index: number) => (
                <ActivityCard key={`today-${sale.id}-${index}`} {...sale} clickToDetails />
              ))}
        </>
      )}
    </Paper>
  );
};

export default Activity;
