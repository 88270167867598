import { NavLink, useLocation, Location } from 'react-router-dom';
import { Drawer, List, Theme, Box, Typography, ListItem, Avatar, Button, SxProps } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { measures } from '../../data/measures';
import { navigationItems, NavigationItem } from '../../data/navigation';
import { colors } from '../../data/colors';
import { useAuthContext } from '../../hooks/useAuth';
import { getAvatarInitials, getHelloName } from '../../helpers/avatarHelper';
import CustomSvgIcon from './icons/CustomSvgIcon';

const toolbarStyles = ({ mixins }: Theme): SystemStyleObject<Theme> => ({
  ...mixins.toolbar,
  bgcolor: colors.indigo[700],
  display: 'flex',
  alignItems: 'center',
  px: 2,
});

const sidebarStyles: SxProps = {
  padding: '1.5rem 1.5rem 2rem',
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: measures.sidebarWidth,
  boxSizing: 'border-box',
  bgcolor: colors.gray[50],
};

const listStyles: SxProps = {
  '& a': {
    color: colors.gray[900],
    textDecoration: 'none',
    '& svg': { color: colors.gray[500] },
  },
};

const sidebarItemStyles: SxProps = {
  padding: '0.5rem',
  bgcolor: 'transparent',
  borderRadius: 1.5,
  '&:hover': { bgcolor: colors.gray[300] },
};

const activeSidebarItemStyles: SxProps = { ...sidebarItemStyles, bgcolor: colors.gray[100] };

const bottomActionStyles: SxProps = {
  py: 2,
  px: 1.5,
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  color: colors.gray[500],
  borderTop: `1px solid ${colors.gray[300]}`,
  borderRadius: 0,
  '&:hover': { bgcolor: colors.gray[300] },
};

const svgStyles: SxProps = { stroke: colors.gray[500] };

interface SidebarProps {
  mobileOpen: boolean;
  handleDrawerToggle: () => void;
}

function Sidebar({ mobileOpen, handleDrawerToggle }: SidebarProps) {
  const { pathname }: Location = useLocation();
  const {
    auth: { user },
    logout,
  } = useAuthContext();

  const handleLogout = () => logout();

  const drawer = (
    <>
      <Box sx={toolbarStyles}>
        <Avatar alt={user?.name} src={user?.pictureURL} sx={{ mr: 2 }}>
          {!user?.pictureURL && getAvatarInitials(user?.name!)}
        </Avatar>
        <Typography sx={{ color: colors.white }}>{getHelloName(user?.name!)}</Typography>
      </Box>
      <Box sx={sidebarStyles}>
        <List sx={listStyles}>
          {navigationItems.map((navigationItem: NavigationItem, index: number) => {
            const isActiveItem: boolean = pathname.includes(navigationItem.to);

            return (
              <NavLink key={index} to={navigationItem.to}>
                <ListItem sx={isActiveItem ? activeSidebarItemStyles : sidebarItemStyles} button>
                  <CustomSvgIcon icon="trendingUp" sx={svgStyles} />
                  <Typography ml={2}>{navigationItem.name}</Typography>
                </ListItem>
              </NavLink>
            );
          })}
        </List>
        <Button sx={bottomActionStyles} variant="text" onClick={handleLogout}>
          <CustomSvgIcon icon="logout" sx={svgStyles} />
          <Typography ml={2}>Cerrar Sesión</Typography>
        </Button>
      </Box>
    </>
  );

  return (
    <>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{ display: { xs: 'block', md: 'none' } }}
      >
        {drawer}
      </Drawer>
      <Drawer variant="permanent" sx={{ display: { xs: 'none', md: 'block' } }}>
        {drawer}
      </Drawer>
    </>
  );
}

export default Sidebar;
