import { SvgIcon, SvgIconProps, SxProps } from '@mui/material';

export const svgPath = {
  chevronLeft: <path d="M15 19L8 12L15 5" />,
  logout: (
    <path d="M17 16L21 12M21 12L17 8M21 12L7 12M13 16V17C13 18.6569 11.6569 20 10 20H6C4.34315 20 3 18.6569 3 17V7C3 5.34315 4.34315 4 6 4H10C11.6569 4 13 5.34315 13 7V8" />
  ),
  menu: <path d="M4 6H20M4 12H20M4 18H20" />,
  refresh: (
    <path d="M4 4V9H4.58152M19.9381 11C19.446 7.05369 16.0796 4 12 4C8.64262 4 5.76829 6.06817 4.58152 9M4.58152 9H9M20 20V15H19.4185M19.4185 15C18.2317 17.9318 15.3574 20 12 20C7.92038 20 4.55399 16.9463 4.06189 13M19.4185 15H15" />
  ),
  trendingUp: <path d="M13 7H21M21 7V15M21 7L13 15L9 11L3 17" />,
  xCircle: (
    <path d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM8.70711 7.29289C8.31658 6.90237 7.68342 6.90237 7.29289 7.29289C6.90237 7.68342 6.90237 8.31658 7.29289 8.70711L8.58579 10L7.29289 11.2929C6.90237 11.6834 6.90237 12.3166 7.29289 12.7071C7.68342 13.0976 8.31658 13.0976 8.70711 12.7071L10 11.4142L11.2929 12.7071C11.6834 13.0976 12.3166 13.0976 12.7071 12.7071C13.0976 12.3166 13.0976 11.6834 12.7071 11.2929L11.4142 10L12.7071 8.70711C13.0976 8.31658 13.0976 7.68342 12.7071 7.29289C12.3166 6.90237 11.6834 6.90237 11.2929 7.29289L10 8.58579L8.70711 7.29289Z" />
  ),
};

const outlineStyles: SxProps = {
  fill: 'none',
  strokeWidth: '2',
  strokeLinecap: 'round',
  strokeLinejoin: 'round',
};

const solidStyles: SxProps = {
  fillRule: 'evenodd',
  clipRule: 'evenodd',
};

interface CustomSvgIconProps extends SvgIconProps {
  icon: 'chevronLeft' | 'logout' | 'menu' | 'refresh' | 'trendingUp' | 'xCircle';
  variant?: 'outline' | 'solid';
}

const CustomSvgIcon = ({ icon, variant = 'outline', ...rest }: CustomSvgIconProps) => {
  const sxProps: SxProps | {} = rest.sx || {};

  return (
    <SvgIcon {...rest} sx={[variant === 'outline' ? outlineStyles : solidStyles, sxProps]}>
      {svgPath[icon]}
    </SvgIcon>
  );
};

export default CustomSvgIcon;
