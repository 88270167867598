import { Route, Routes, Navigate, Outlet } from 'react-router-dom';
import Dashboard from '../components/common/Dashboard';
import Login from './Login';
import Activity from './Activity';
import ActivityDetails from './ActivityDetails';

const Main = () => (
  <Routes>
    <Route path="/" element={<Dashboard />}>
      <Route path="activity" element={<Outlet />}>
        <Route path="" element={<Activity />} />
        <Route path=":id" element={<ActivityDetails />} />
      </Route>
      <Route path="*" element={<Navigate to="/activity" replace />} />
    </Route>
    <Route path="/login" element={<Login />} />
  </Routes>
);

export default Main;
