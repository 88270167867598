export enum RoutesEnum {
  ACTIVITY = '/activity',
}

export interface NavigationItem {
  name: string;
  to: string;
  icon?: JSX.Element;
}

export const navigationItems: NavigationItem[] = [{ name: 'Actividad', to: RoutesEnum.ACTIVITY }];
