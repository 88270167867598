import { Country } from '../data/country';

export const getCountryName = (country: Country): string => {
  switch (country) {
    case Country.UY:
      return 'Uruguay';
    case Country.BR:
      return 'Brasil';
    default:
      return '';
  }
};
