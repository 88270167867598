import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Main from './screens/Main';
import { colors } from './data/colors';
import { AuthProvider } from './hooks/useAuth';
import { SalesProvider } from './hooks/useSales';
import './styles/app.scss';

const theme = createTheme({
  palette: {
    primary: {
      main: colors.indigo[600],
    },
    secondary: {
      main: colors.gray[900],
      light: colors.gray[500],
    },
    common: {
      white: colors.white,
    },
    error: {
      main: colors.red[500],
    },
    info: {
      main: colors.gray[400],
    },
  },
  typography: {
    fontFamily: 'Inter',
    h2: {
      fontSize: 18,
      lineHeight: '24px',
      fontWeight: 500,
    },
    h3: {
      fontSize: 16,
      lineHeight: '24px',
      fontWeight: 500,
    },
    subtitle1: {
      fontSize: 12,
      lineHeight: '16px',
      fontWeight: 500,
    },
    body1: {
      fontSize: 14,
      lineHeight: '20px',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          font: 'normal 500 16px/24px Inter',
          borderRadius: 6,
          padding: '9px 17px',
          textTransform: 'none',
          transition: 'none',
          '&:focus': {
            outline: `4px solid ${colors.indigo[500]}`,
            outlineOffset: '4px',
          },
        },
        containedPrimary: {
          '&:hover': { backgroundColor: colors.indigo[700] },
          '&:focus': { backgroundColor: colors.indigo[600] },
        },
        containedSecondary: {
          backgroundColor: colors.indigo[200],
          color: colors.indigo[700],
          '&:hover': { backgroundColor: colors.indigo[300] },
          '&:focus': { backgroundColor: colors.indigo[200] },
        },
        containedInfo: {
          backgroundColor: colors.white,
          color: colors.gray[700],
          border: `1px solid ${colors.gray[300]}`,
          '&:hover': { backgroundColor: colors.gray[50] },
          '&:focus': { backgroundColor: colors.white },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          height: 40,
          width: 40,
          mr: 2,
          bgcolor: colors.gray[500],
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          height: '1.25rem',
        },
        label: {
          fontSize: 12,
          lineHeight: '16px',
          fontWeight: 500,
        },
      },
    },
  },
});

function App() {
  return (
    <AuthProvider>
      <SalesProvider>
        <BrowserRouter>
          <ThemeProvider theme={theme}>
            <Main />
          </ThemeProvider>
        </BrowserRouter>
      </SalesProvider>
    </AuthProvider>
  );
}

export default App;
