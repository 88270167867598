import { useContext, useMemo, useCallback, useReducer } from 'react';
import { AuthContext } from '../context/auth.context';
import { AUTH_ACTION_TYPES, initialState, authReducer } from '../context/auth.reducer';
import { authService } from '../services/auth.service';
import { LoginRequest } from '../types/auth.types';

//#region provider

const AuthProvider = (props: any) => {
  const [auth, dispatch] = useReducer(authReducer, initialState);

  const login = useCallback(async (loginRequest: LoginRequest) => {
    try {
      dispatch({ type: AUTH_ACTION_TYPES.LOGGING_IN });

      const loginResponse = await authService.login(loginRequest);

      localStorage.setItem('authToken', loginResponse.data.token);

      dispatch({
        type: AUTH_ACTION_TYPES.LOGIN_SUCCESS,
        user: { ...loginResponse?.data?.user, email: loginRequest.email },
      });
    } catch (error: any) {
      dispatch({ type: AUTH_ACTION_TYPES.LOGIN_ERROR, errorMessage: error });
    }
  }, []);

  const logout = useCallback(async () => {
    try {
      // TODO: check why this returns always `200: { "result": false, "needUpdate": false, "errorCode": 1}`
      // when fixed, change to appropriate try/catch implementation
      await authService.logout();
    } finally {
      localStorage.removeItem('authToken');
      dispatch({ type: AUTH_ACTION_TYPES.LOGOUT });
    }
  }, []);

  const authData = useMemo(() => ({ auth, login, logout }), [auth, login, logout]);

  return <AuthContext.Provider value={authData} {...props} />;
};

//#endregion

//#region custom hook

const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthContext must be used within an AuthProvider');
  }

  return context;
};

//#endregion

export { AuthProvider, useAuthContext };
