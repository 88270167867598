import { Box, SxProps, Typography } from '@mui/material';
import { colors } from '../../data/colors';
import { texts } from '../../data/texts';
import CustomSvgIcon from './icons/CustomSvgIcon';

interface FixedErrorProps {
  message?: string;
}

const paperStyle: SxProps = {
  width: { xs: '100vw', md: '100%' },
  height: 'fit-content',
  mt: 3,
  mx: { md: 3 },
  py: 2,
  px: 2.25,
  bgcolor: colors.red[50],
  color: colors.red[800],
  boxShadow: 1,
  borderRadius: '6px',
  display: 'flex',
  gap: 0.25,
};

const FixedError = ({ message }: FixedErrorProps) => (
  <Box sx={paperStyle}>
    <CustomSvgIcon icon="xCircle" variant="solid" sx={{ fill: colors.red[300] }} />
    <Typography variant="body1" fontWeight="500">
      {message || texts.operationsError}
    </Typography>
  </Box>
);

export default FixedError;
