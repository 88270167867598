import { Typography, IconButton, SxProps } from '@mui/material';
import { colors } from '../../data/colors';
import { ViewTitle } from '../../helpers/viewHelper';
import CustomSvgIcon from './icons/CustomSvgIcon';

const leftIconStyles: SxProps = { left: '-6px', mr: 0 };

const rightIconStyles: SxProps = {
  right: { xs: '-16px', md: 0 },
  ml: { xs: 'auto', md: 1 },
};

const outlineSvgStyles: SxProps = { stroke: colors.white };

const textStyles: SxProps = { fontFamily: 'Roboto', letterSpacing: '0.5px' };

interface HeaderToolbarContentProps {
  isSidebarView: boolean;
  viewTitle: ViewTitle;
  leftIconHandler: () => void;
  rightIconHandler: () => void;
}

const HeaderToolbarContent = ({
  isSidebarView,
  viewTitle,
  leftIconHandler,
  rightIconHandler,
}: HeaderToolbarContentProps) => {
  return (
    <>
      <IconButton
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={[leftIconStyles, isSidebarView && { display: { md: 'none' } }]}
        onClick={leftIconHandler}
      >
        <CustomSvgIcon icon={isSidebarView ? 'menu' : 'chevronLeft'} sx={outlineSvgStyles} />
      </IconButton>
      <Typography variant="h3" sx={textStyles}>
        {viewTitle}
      </Typography>
      {isSidebarView && (
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="refresh"
          sx={rightIconStyles}
          onClick={rightIconHandler}
        >
          <CustomSvgIcon icon="refresh" sx={outlineSvgStyles} />
        </IconButton>
      )}
    </>
  );
};

export default HeaderToolbarContent;
