import { Params } from 'react-router-dom';

export enum ViewTitle {
  SALES = 'Actividad',
  SALE_DETAIL = 'Detalle de la operación',
}

export const getViewTitle = (pathname: string, params: Params<string>): ViewTitle | undefined => {
  if (params.id && pathname === `/activity/${params.id}`) {
    return ViewTitle.SALE_DETAIL;
  }

  if (pathname === '/activity') {
    return ViewTitle.SALES;
  }
  return undefined;
};
