import axios, { AxiosInstance } from 'axios';
import { config } from '../config/config';

const instance: AxiosInstance = axios.create({ baseURL: config.backendUrl });

// Append token on each request
instance.interceptors.request.use(
  (config) => {
    const authToken = localStorage.getItem('authToken');
    if (authToken && config.headers !== undefined) {
      if (!config.headers.Authorization?.includes('Basic', 0)) {
        config.headers.Authorization = `Bearer ${authToken}`;
      }
    }
    return config;
  },
  (err) => Promise.reject(err),
);

instance.interceptors.response.use(
  async (response: any) => {
    if (response?.data?.result === false) {
      const errorMessage: string = response?.data?.showMessage?.ES || response?.data?.message || 'error';

      return Promise.reject(errorMessage);
    }
    return response;
  },
  (error) => {
    // Delete token from localStorage if status is unauthorized
    if (error?.response?.status === 401) {
      localStorage.removeItem('authToken');
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default instance;
