import { useCallback, useContext, useMemo, useReducer } from 'react';
import { SalesContext } from '../context/sales.context';
import { SALES_ACTION_TYPES, initialState, salesReducer } from '../context/sales.reducer';
import { salesService } from '../services/sales.service';

//#region provider

const SalesProvider = (props: any) => {
  const [sales, dispatch] = useReducer(salesReducer, initialState);

  const getSales = useCallback(async (email: string) => {
    try {
      dispatch({ type: SALES_ACTION_TYPES.GETTING_SALES });

      const salesResponse = await salesService.getSales(email);

      dispatch({ type: SALES_ACTION_TYPES.GET_SALES_SUCCESS, sales: salesResponse?.data });
    } catch (error: any) {
      dispatch({ type: SALES_ACTION_TYPES.GET_SALES_ERROR, salesErrorMessage: error });
    }
  }, []);

  const getSaleDetails = useCallback(async (email: string, saleId: number) => {
    try {
      dispatch({ type: SALES_ACTION_TYPES.GETTING_SALE_DETAILS });

      const salesResponse = await salesService.getSaleDetail(email, saleId);

      dispatch({ type: SALES_ACTION_TYPES.GET_SALE_DETAILS_SUCCESS, saleDetail: salesResponse?.data });
    } catch (error: any) {
      dispatch({ type: SALES_ACTION_TYPES.GET_SALE_DETAILS_ERROR, saleDetailErrorMessage: error });
    }
  }, []);

  const salesData = useMemo(() => ({ sales, getSales, getSaleDetails }), [sales, getSales, getSaleDetails]);

  return <SalesContext.Provider value={salesData} {...props} />;
};

//#endregion

//#region custom hook

const useSalesContext = () => {
  const context = useContext(SalesContext);

  if (context === undefined) {
    throw new Error('useSalesContext must be used within a SalesProvider');
  }

  return context;
};

//#endregion

export { SalesProvider, useSalesContext };
