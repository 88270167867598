import { KeyboardEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Avatar, Box, Typography, SxProps, Theme } from '@mui/material';
import moment from 'moment';
import 'moment/locale/es';
import { getAvatarInitials } from '../../helpers/avatarHelper';
import { getSaleStatusData } from '../../helpers/salesHelper';
import { Sale } from '../../types/sale.types';
import { colors } from '../../data/colors';
import { RoutesEnum } from '../../data/navigation';
import SalesStatusPill from './SalesStatusPill';

const cardStyles: SxProps<Theme> = {
  display: 'flex',
  px: 2,
  py: 1.25,
  flexWrap: 'wrap',
  outlineColor: (theme: Theme) => theme.palette.primary.main,
};

const listCardStyles: SxProps = { borderRadius: 'none', boxShadow: 'none' };

const detailCardStyles: SxProps = {
  flexDirection: 'column',
  py: 2,
  gap: 0.5,
};

const clickCardStyles: SxProps = {
  cursor: 'pointer',
  transition: 'background-color 0.5s',
  '&:hover': { bgcolor: colors.gray[300] },
};

const spreadWrapper: SxProps = {
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  flexWrap: 'nowrap',
};

const columnWrapper: SxProps = { display: 'flex', flexDirection: 'column', flexWrap: 'wrap' };

interface ActivityCardProps extends Sale {
  clickToDetails?: boolean;
  cardDisplay?: 'list' | 'detail';
}

const ActivityCard = ({
  id,
  user,
  document,
  status,
  amountUSD,
  createdAt,
  clickToDetails,
  cardDisplay = 'list',
}: ActivityCardProps) => {
  const navigate = useNavigate();
  const fullName = !!user ? `${user?.firstName} ${user?.lastName}` : document;
  const saleStatusData = getSaleStatusData(status);

  const cardClickHandler = () => clickToDetails && navigate(`${RoutesEnum.ACTIVITY}/${id}`);

  return (
    <Card
      sx={[
        cardStyles,
        cardDisplay === 'list' ? listCardStyles : detailCardStyles,
        !!clickToDetails && clickCardStyles,
      ]}
      onClick={cardClickHandler}
      onKeyDown={(event: KeyboardEvent<HTMLDivElement>) => event.key === 'Enter' && cardClickHandler()}
      tabIndex={cardDisplay === 'list' ? 0 : undefined}
    >
      <Avatar alt={fullName!} sx={cardDisplay === 'list' ? { mr: 2 } : { mx: 'auto' }}>
        {!!user ? getAvatarInitials(fullName!) : 'pix'}
      </Avatar>
      <Box sx={spreadWrapper}>
        <Box sx={columnWrapper}>
          <Box
            sx={[
              { display: 'flex', flexWrap: 'wrap', alignItems: 'center' },
              cardDisplay === 'detail' && { flexDirection: { xs: 'column', md: 'row' } },
            ]}
          >
            <Typography variant="h3" mr={1} color={colors.gray[900]}>
              {fullName}
            </Typography>
            <SalesStatusPill {...saleStatusData} />
          </Box>
          <Typography
            variant="body1"
            sx={[
              { color: 'secondary.light', textAlign: 'left' },
              cardDisplay === 'detail' && { display: { xs: 'none', md: 'block' } },
            ]}
          >
            Operación: {id}
          </Typography>
        </Box>
        <Box sx={columnWrapper}>
          <Typography variant="h3" textAlign="right">
            USD {amountUSD}
          </Typography>
          <Typography variant="body1" color="secondary.light" textAlign="right">
            {moment(createdAt).calendar(null, {
              sameDay: '[Hoy] LT',
              lastDay: '[Ayer] LT',
              sameElse: 'L LT',
            })}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default ActivityCard;
