import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Paper, Card, Typography, SxProps } from '@mui/material';
import ActivityCard from '../components/activity/ActivityCard';
import FixedError from '../components/common/FixedError';
import Loading from '../components/common/animations/Loading';
import { getCountryName } from '../helpers/countryHelper';
import { useAuthContext } from '../hooks/useAuth';
import { useSalesContext } from '../hooks/useSales';

const paperStyle: SxProps = {
  width: { xs: '100vw', md: '60%' },
  height: 'fit-content',
  borderRadius: { xs: 'none', md: 2 },
  mt: { md: 3 },
};

const paperDetailsStyle: SxProps = {
  p: { xs: '1.5rem 1.25rem', md: 0 },
  bgcolor: 'transparent',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
};

const cardInfoStyle: SxProps = {
  p: 2,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 0.5,
};

const ActivityDetails = () => {
  const { auth } = useAuthContext();
  const { sales, getSaleDetails } = useSalesContext();
  const { id } = useParams();

  useEffect(() => {
    if (+id! !== sales?.saleDetail?.id || !sales.loading && !sales.saleDetail && !sales.saleDetailErrorMessage) {
      getSaleDetails(auth.user?.email!, +id!);
    }
  }, [auth.user?.email, id, getSaleDetails, sales.saleDetailErrorMessage, sales.loading, sales.saleDetail]);

  return !sales.loading && (sales.saleDetailErrorMessage || !sales?.saleDetail) ? (
    <FixedError />
  ) : (
    <Paper sx={[paperStyle, !sales.loading && paperDetailsStyle]} elevation={0}>
      {sales.loading ? (
        <Loading />
      ) : (
        <>
          <ActivityCard {...sales.saleDetail!} cardDisplay="detail" />
          <Card sx={cardInfoStyle}>
            <Typography variant="h3" fontWeight="600">
              Medio de pago
            </Typography>
            <Typography variant="h3">{getCountryName(sales.saleDetail?.user.country!)}</Typography>
            <Typography variant="h3">
              Tarjeta {sales.saleDetail?.creditCard?.brand} terminada en {sales.saleDetail?.creditCard?.last4}
            </Typography>
          </Card>
        </>
      )}
    </Paper>
  );
};

export default ActivityDetails;
