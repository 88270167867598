import sha256 from 'crypto-js/sha256';
import { config } from '../config/config';
import { LoginRequest } from '../types/auth.types';
import axios from './axios';

export const authService = {
  login,
  logout,
};

async function login(loginRequest: LoginRequest) {
  const hashedPassword: string = sha256(loginRequest.password).toString();

  const res = await axios.post(`${config.backendUrl}/business/login`, {
    ...loginRequest,
    password: hashedPassword,
  });
  return res.data;
}

async function logout() {
  const res = await axios.post(`${config.backendUrl}/business/logout`);
  return res.data;
}
