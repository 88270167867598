import { Box, Typography } from '@mui/material';
import Lottie from 'lottie-react';
import loadingAnimation from '../../../assets/loading.json';
import { colors } from '../../../data/colors';

const Loading = () => {
  return (
    <Box sx={{ p: 2 }}>
      <Lottie animationData={loadingAnimation} style={{ height: '124px' }} />
      <Typography variant="h3" color={colors.gray[900]}>
        Por favor espere...
      </Typography>
    </Box>
  );
};

export default Loading;
