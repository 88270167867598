import { AuthState } from '../types/auth.types';
import { AuthUser } from '../types/user.types';

//#region action types

export enum AUTH_ACTION_TYPES {
  LOGGING_IN = 'LOGGING_IN',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_ERROR = 'LOGIN_ERROR',
  LOGOUT = 'LOGOUT',
}

export type AuthAction =
  | { type: typeof AUTH_ACTION_TYPES.LOGGING_IN }
  | { type: typeof AUTH_ACTION_TYPES.LOGIN_SUCCESS; user: AuthUser }
  | { type: typeof AUTH_ACTION_TYPES.LOGIN_ERROR; errorMessage: string }
  | { type: typeof AUTH_ACTION_TYPES.LOGOUT };

//#endregion

//#region reducer

export const initialState: AuthState = {
  loading: false,
  isLoggedIn: false,
  errorMessage: null,
  user: null,
};

export const authReducer = (state: AuthState, action: AuthAction) => {
  switch (action.type) {
    case AUTH_ACTION_TYPES.LOGGING_IN:
      return {
        ...state,
        loading: true,
        isLoggedIn: false,
        errorMessage: null,
      };
    case AUTH_ACTION_TYPES.LOGIN_SUCCESS:
      return {
        loading: false,
        isLoggedIn: true,
        errorMessage: null,
        user: action.user,
      };
    case AUTH_ACTION_TYPES.LOGIN_ERROR: {
      return {
        loading: false,
        isLoggedIn: false,
        errorMessage: action.errorMessage,
        user: null,
      };
    }
    case AUTH_ACTION_TYPES.LOGOUT:
      return {
        loading: false,
        isLoggedIn: false,
        errorMessage: null,
        user: null,
      };
    default:
      return state;
  }
};

//#endregion
