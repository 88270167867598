import { createContext } from 'react';
import { SalesState } from '../types/sale.types';

type SalesContextType = {
  sales: SalesState;
  getSales: (email: string) => Promise<void>;
  getSaleDetails: (email: string, saleId: number) => Promise<void>;
};

export const SalesContext = createContext<SalesContextType | undefined>(undefined);
