import * as Yup from 'yup';

const emailFormatError = 'Debe tener formato de email';
const requiredFieldError = 'Campo requerido';

const LoginSchema = Yup.object().shape({
  email: Yup.string().email(emailFormatError).required(requiredFieldError),
  password: Yup.string().required(requiredFieldError),
});

const schemas = {
  LoginSchema,
};

export default schemas;
