import { Sale, SalesState } from '../types/sale.types';

//#region action types

export enum SALES_ACTION_TYPES {
  GETTING_SALES = 'GETTING_SALES',
  GET_SALES_SUCCESS = 'GET_SALES_SUCCESS',
  GET_SALES_ERROR = 'GET_SALES_ERROR',
  GETTING_SALE_DETAILS = 'GETTING_SALE_DETAILS',
  GET_SALE_DETAILS_SUCCESS = 'GET_SALE_DETAILS_SUCCESS',
  GET_SALE_DETAILS_ERROR = 'GET_SALE_DETAILS_ERROR',
}

export type SalesAction =
  | { type: typeof SALES_ACTION_TYPES.GETTING_SALES }
  | { type: typeof SALES_ACTION_TYPES.GET_SALES_SUCCESS; sales: Sale[] }
  | { type: typeof SALES_ACTION_TYPES.GET_SALES_ERROR; salesErrorMessage: string };

export type SaleDetailAction =
  | { type: typeof SALES_ACTION_TYPES.GETTING_SALE_DETAILS }
  | { type: typeof SALES_ACTION_TYPES.GET_SALE_DETAILS_SUCCESS; saleDetail: Sale }
  | { type: typeof SALES_ACTION_TYPES.GET_SALE_DETAILS_ERROR; saleDetailErrorMessage: string };

export type SalesStateAction = SalesAction | SaleDetailAction;

//#endregion

//#region reducer

export const initialState: SalesState = {
  loading: false,
  salesErrorMessage: null,
  saleDetailErrorMessage: null,
  sales: null,
  saleDetail: null,
};

export const salesReducer = (state: SalesState, action: SalesStateAction) => {
  switch (action.type) {
    case SALES_ACTION_TYPES.GETTING_SALES:
      return {
        ...state,
        loading: true,
        salesErrorMessage: null,
      };
    case SALES_ACTION_TYPES.GET_SALES_SUCCESS:
      return {
        ...state,
        loading: false,
        salesErrorMessage: null,
        sales: action.sales,
      };
    case SALES_ACTION_TYPES.GET_SALES_ERROR:
      return {
        ...state,
        loading: false,
        salesErrorMessage: action.salesErrorMessage,
        sales: null,
      };
    case SALES_ACTION_TYPES.GETTING_SALE_DETAILS:
      return {
        ...state,
        loading: true,
        saleDetailErrorMessage: null,
      };
    case SALES_ACTION_TYPES.GET_SALE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        saleDetailErrorMessage: null,
        saleDetail: action.saleDetail,
      };
    case SALES_ACTION_TYPES.GET_SALE_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        saleDetailErrorMessage: action.saleDetailErrorMessage,
        saleDetail: null,
      };
    default:
      return state;
  }
};

//#endregion
