import moment from 'moment';
import { SaleStatus } from '../data/saleStatus';
import { SalesStatusPillProps } from '../components/activity/SalesStatusPill';
import { Sale } from '../types/sale.types';

export interface SplitSales {
  todaySales: Sale[];
  beforeTodaySales: Sale[];
}

export const getSaleStatusData = (saleStatus: SaleStatus): SalesStatusPillProps => {
  switch (saleStatus) {
    case SaleStatus.PAID:
      return {
        label: 'Paid',
        variant: 'green',
      };
    case SaleStatus.REJECTED:
      return {
        label: 'Rejected',
        variant: 'red',
      };
    case SaleStatus.REFUNDED:
      return {
        label: 'Refunded',
        variant: 'yellow',
      };
    case SaleStatus.PENDING:
      return {
        label: 'Pending',
        variant: 'blue',
      };
    case SaleStatus.PENDING_REFUND:
      return {
        label: 'Pending refund',
        variant: 'gray',
      };
    default:
      return {
        label: 'Approved',
        variant: 'green',
      };
  }
};

export const getSalesSplitByToday = (sales: Sale[]): SplitSales => {
  const today = moment();
  const todaySales: Sale[] = [];
  const beforeTodaySales: Sale[] = [];

  sales.forEach((sale: Sale) =>
    moment(sale.createdAt).isSame(today, 'day') ? todaySales.push(sale) : beforeTodaySales.push(sale),
  );

  return { todaySales, beforeTodaySales };
};

export const getTotalAmount = (sales?: Sale[]): number => {
  if (!sales || !sales.length) return 0;

  return sales.reduce(
    (previousAmount: number, currentSale: Sale) => previousAmount + currentSale.amountUSD,
    0,
  );
};
