import { Box, Theme } from '@mui/material';
import { SystemStyleObject } from '@mui/system';
import { Navigate, Outlet } from 'react-router-dom';
import { colors } from '../../data/colors';
import { measures } from '../../data/measures';
import { useAuthContext } from '../../hooks/useAuth';
import Header from './Header';

const contentStyles: SystemStyleObject<Theme> = {
  minHeight: `calc(100vh - ${measures.headerHeight}px)`,
  mt: `${measures.headerHeight}px`,
  bgcolor: colors.gray[100],
  display: 'flex',
  justifyContent: 'center',
  width: { xs: '100vw', md: `calc(100vw - ${measures.sidebarWidth}px)` },
  boxSizing: 'border-box',
  ml: { md: `${measures.sidebarWidth}px` },
};

const Dashboard = () => {
  const { auth } = useAuthContext();

  return auth.isLoggedIn ? (
    <div className="main">
      <Header />
      <Box component="main" sx={contentStyles}>
        <Outlet />
      </Box>
    </div>
  ) : (
    <Navigate to="/login" replace />
  );
};

export default Dashboard;
