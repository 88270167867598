import { createContext } from 'react';
import { LoginRequest, AuthState } from '../types/auth.types';

type AuthContextType = {
  auth: AuthState;
  login: (loginRequest: LoginRequest) => Promise<void>;
  logout: () => Promise<void>;
};

export const AuthContext = createContext<AuthContextType | undefined>(undefined);
